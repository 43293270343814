import React from 'react'
import WebpImage from '../../WebpImage'

import "./style.scss"
import {hubbleClassic} from "../../../services/data";

const AffiliateWhyHubble = ({ isV3, isOtp }) => {
  return (
    <div className={`why-hubble-affiliate grid-section ${isOtp ? "otp" : ""}`}>
      <h2 className="headline">Why Hubble?</h2>

      <div className="value-props-holder">
        {isOtp && <>
          <div className="value-prop">
            <WebpImage
              fileName="Pages/MilitaryPage/MilitaryValuePropsSection/00-Hubble-Contacts-Help-Policies.png"
              alt=""
            />
            <p className="value-title">We’re Here to Help</p>
            <p className="value-copy">
              Give us a call. Our customer service team is available 7 days a week.
            </p>
          </div>
          <div className="value-prop">
            <WebpImage
              fileName="Pages/MilitaryPage/MilitaryValuePropsSection/01-Hubble-Contacts-Start-for-$1.png"
              alt=""
            />
            <p className="value-title">$1 a Day</p>
            <p className="value-copy">
              Our daily lenses are available at $90 for a 90 day supply...you do the math.
            </p>
          </div>
        </>
        }

        {!isOtp && <>
          <div className="value-prop">
            <WebpImage
              fileName="Pages/MilitaryPage/MilitaryValuePropsSection/01-Hubble-Contacts-Start-for-$1.png"
              alt=""
            />
            <p className="value-title">{isV3 ? "Start for $1" : "New Customer Savings"}</p>
            <p className="value-copy">
              {isV3
                ? `Pay $1 for your first ${hubbleClassic.trialPeriodPairs} pairs of our classic lenses – or start SkyHy or Hydro by Hubble for a special price.`
                : "Get Hydro or SkyHy by Hubble for 40% off your first order."}
            </p>
          </div>

          <div className="value-prop">
            <WebpImage
              fileName="Pages/MilitaryPage/MilitaryValuePropsSection/02-Hubble-Contacts-No-Hassle-Policies.png"
              alt=""
            />
            <p className="value-title">No-Hassle Policies</p>
            <p className="value-copy">Not seeing the value? Just send it back.</p>
          </div>

          <div className="value-prop">
            <WebpImage
              fileName="Pages/MilitaryPage/MilitaryValuePropsSection/03-Hubble-Contacts-Cancel-Any-Time.png"
              alt="Cancel your subscription at any time"
            />
            <p className="value-title">Cancel at any Time</p>
            <p className="value-copy">
              Cancel your contact lens subscription at any time, for any reason.
            </p>
          </div>
        </>
        }

        <div className="value-prop">
          <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/04-Hubble-Contacts-Eye-Health.png"
            alt=""
          />
          <p className="value-title">Eye Health & Safety</p>
          <p className="value-copy">
            All Hubble lenses are FDA-approved, thoroughly inspected, and made with care.
          </p>
        </div>

        <div className="value-prop">
          <WebpImage
            fileName="Pages/MilitaryPage/MilitaryValuePropsSection/05-Hubble-Contacts-Secure-Payment.png"
            alt=""
          />
          <p className="value-title">Secure Payment</p>
          <p className="value-copy">
            Safety first across all payment platforms.
          </p>
        </div>
      </div>
      {isV3 ? null : (
        <a href="#order-section" className="start-button">
          Start Your Subscription
        </a>
      )}
    </div>
  )
}

export default AffiliateWhyHubble
