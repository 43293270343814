export const getGroupedProducts = (hubblePricing) => ({
  hubble: [
    {
      value: "hubble",
      percent_off: "0",
      link: "",
      title: "Hubble Classic Daily",
      long_title: "Hubble Classic Daily Contact Lenses",
      image: "",
      price: "22.99",
      sale_price: "1.00",
      cc_product_id: "hubble",
      days: "28",
      quantity: 30
    },
    {
      value: "hydro",
      percent_off: hubblePricing.hydro.percentOff,
      link: "",
      title: "Hydro by Hubble Daily",
      long_title: "Hydro by Hubble Daily Contact Lenses",
      short_title: "Hydro By Hubble",
      image: "",
      price: hubblePricing.hydro.subPrice,
      sale_price: hubblePricing.hydro.firstPrice,
      cc_product_id: "hydro",
      days: "28",
      quantity: 30
    },
    {
      value: "skyhy",
      percent_off: hubblePricing.skyhy.percentOff,
      link: "",
      title: "SkyHy by Hubble Daily",
      long_title: "SkyHy by Hubble Daily Contact Lenses",
      short_title: "SkyHy by Hubble",
      image: "",
      price: hubblePricing.skyhy.subPrice,
      sale_price: hubblePricing.skyhy.firstPrice,
      cc_product_id: "skyhy",
      days: "28",
      quantity: 30
    },
    {
      value: "skyhy-monthly",
      percent_off: hubblePricing.skyhyMonthly.percentOff,
      link: "",
      title: "SkyHy by Hubble Monthly",
      long_title: "SkyHy by Hubble Monthly Contact Lenses",
      short_title: "SkyHy by Hubble",
      image: "SkyHy-Monthly",
      price: hubblePricing.skyhyMonthly.subPrice,
      price_per_month: hubblePricing.skyhyMonthly.subPricePerMonth,
      sale_price: hubblePricing.skyhyMonthly.firstPrice,
      sale_price_per_month: hubblePricing.skyhyMonthly.firstPricePerMonth,
      cc_product_id: "skyhy-monthly",
      days: "168",
      quantity: 6,
    },
  ],
  hubbleOtp: [
    {
      value: "hubble",
      percent_off: "0",
      link: "",
      title: "Hubble Classic Daily",
      long_title: "Hubble Classic Daily Contact Lenses",
      image: "",
      price: "69.99",
      sale_price: "45.00",
      cc_product_id: "hubble",
      days: "84",
      quantity: 90
    },
    {
      value: "hydro",
      percent_off: "0",
      link: "",
      title: "Hydro by Hubble Daily",
      long_title: "Hydro by Hubble Daily Contact Lenses",
      short_title: "Hydro By Hubble",
      image: "",
      price: "74.99",
      sale_price: "45.00",
      cc_product_id: "hydro",
      days: "84",
      quantity: 90
    },
    {
      value: "skyhy",
      percent_off: "0",
      link: "",
      title: "SkyHy by Hubble Daily",
      long_title: "SkyHy by Hubble Daily Contact Lenses",
      short_title: "SkyHy by Hubble",
      image: "",
      price: "89.99",
      sale_price: "45.00",
      cc_product_id: "skyhy",
      days: "84",
      quantity: 90
    },
  ],
  astigmatism: [
    {
      value: "brand-acuvue-moist-astigmatism",
      percent_off: "40",
      sku: "ACM1DAYASTIG",
      link: "https://www.contactscart.com/products/1-day-acuvue-moist-for-astigmatism",
      title: "1-Day Acuvue Moist for Astigmatism (30 pack)",
      image: "1-Day-Acuvue-Moist-Astigmatism-30pk",
      price: "54.99",
      sale_price: "32.99",
      cc_product_id: "1765072797796",
      days: "28",
      quantity: 30
    },
    {
      value: "brand-acuvue-moist-astigmatism-90",
      percent_off: "40",
      sku: "AC1DMASTIG90",
      link: "",
      title: "1-Day Acuvue Moist for Astigmatism (90 pack)",
      image: "1-Day-Acuvue-Moist-Astigmatism-90pk",
      price: "114.99",
      sale_price: "68.99",
      cc_product_id: "3344340287588",
      days: "84",
      quantity: 90
    },
    {
      value: "acuvue-oasys-1-day-hydraluxe-astig-30",
      percent_off: "40",
      sku: "AC1DOA30",
      link: "https://www.contactscart.com/products/acuvue-oasys-1-day-for-astigmatism-30pk",
      title: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism (30 pack)",
      image: "Acuvue-Oasys-1-Day-Hydraluxe-Astigmatism-30pk",
      price: "62.99",
      sale_price: "37.79",
      cc_product_id: "3409322016868",
      days: "28",
      quantity: 30
    },
    {
      value: "acuvue-oasys-1-day-hydraluxe-astig-90",
      percent_off: "40",
      sku: "AC1DOA90",
      link: "https://www.contactscart.com/products/acuvue-oasys-1-day-with-hydraluxe-for-astigmatism-90pk",
      title: "Acuvue Oasys 1-Day with Hydraluxe for Astigmatism (90 pack)",
      image: "Acuvue-Oasys-1-Day-Hydraluxe-Astigmatism-90pk",
      price: "129.99",
      sale_price: "77.99",
      cc_product_id: "7165851992164",
      days: "84",
      quantity: 90
    },
    {
      value: "brand-acuvue-astigmatism",
      percent_off: "40",
      sku: "AVOA",
      link: "https://www.contactscart.com/products/acuvue-oasys-for-astigmatism",
      title: "Acuvue Oasys for Astigmatism (6 pack)",
      image: "Acuvue-Oasys-Astigmatism-6pk",
      price: "62.99",
      sale_price: "37.79",
      cc_product_id: "1765080399972",
      days: "84",
      quantity: 6
    },
    {
      value: "brand-acuvue-vita-6pk-astig",
      percent_off: "40",
      sku: "AVA",
      link: "https://www.contactscart.com/products/acuvue-vita-for-astigmatism",
      title: "Acuvue Vita for Astigmatism (6 pack)",
      image: "Acuvue-Vita-Astigmatism-6pk",
      price: "79.99",
      sale_price: "47.99",
      cc_product_id: "3379060179044",
      days: "168",
      quantity: 6
    },
    {
      value: "bausch-lomb-astig",
      percent_off: "40",
      sku: "ULTRASTIG6",
      link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
      title: "Bausch + Lomb ULTRA for Astigmatism (6 pack)",
      image: "B+L-Ultra-Astigmatism-6pk",
      price: "72.99",
      sale_price: "43.79",
      cc_product_id: "3341501268068",
      days: "168",
      quantity: 6
    },
    {
      value: "biotrue-oneday-astig-30",
      percent_off: "40",
      sku: "B1DAYAST30",
      link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
      title: "Biotrue ONEday Astigmatism (30 pack)",
      image: "Biotrue-ONEDay-Astigmatism-30pk",
      price: "42.99",
      sale_price: "25.79",
      cc_product_id: "3408913530980",
      days: "28",
      quantity: 30
    },
  ],
  popular: [
    {
      value: "acuvue-moist-30-normal",
      percent_off: "60",
      link: "https://www.contactscart.com/products/1-day-acuvue-moist",
      title: "1-Day Acuvue Moist (30 pack)",
      image: "1-Day-Acuvue-Moist-30pk",
      price: "54.99",
      sale_price: "22.00",
      cc_product_id: "1765066866788",
      days: "28",
      quantity: 30
    },
    {
      value: "brand-acuvue-moist",
      percent_off: "40",
      link: "https://www.contactscart.com/products/1-day-acuvue-moist-90pk",
      title: "1-Day Acuvue Moist (90 pack)",
      image: "1-Day-Acuvue-Moist-90pk",
      price: "94.99",
      sale_price: "56.99",
      cc_product_id: "2620861448292",
      days: "84",
      quantity: 90
    },
    {
      value: "brand-acuvue-vita-6pk",
      percent_off: "40",
      link: "https://www.contactscart.com/products/acuvue-vita",
      title: "Acuvue Vita (6 pack)",
      image: "Acuvue-Vita-6pk",
      price: "79.99",
      sale_price: "47.99",
      cc_product_id: "1765082136676",
      days: "168",
      quantity: 6
    },
    {
      value: "brand-acuvue-oyasis-hydraluxe",
      percent_off: "40",
      link: "https://www.contactscart.com/products/acuvue-oasys-1-day",
      title: "Acuvue Oasys 1-Day with Hydraluxe (90 pack)",
      image: "Acuvue-Oasys-1-Day-Hydraluxe-90pk",
      price: "117.99",
      sale_price: "70.79",
      cc_product_id: "1765069357156",
      days: "84",
      quantity: 90
    },
    {
      value: "brand-acuvue-hydraclear-plus",
      percent_off: "40",
      link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
      title: "Acuvue Oasys with Hydraclear Plus (6 pack)",
      image: "Acuvue-Oasys-Hydraclear-Plus-6pk",
      price: "49.99",
      sale_price: "29.99",
      cc_product_id: "1765078499428",
      days: "84",
      quantity: 6
    },
    {
      value: "biotrue-oneday-90",
      percent_off: "40",
      link: "https://www.contactscart.com/collections/top-brands/products/acuvue-oasys-with-hydraclear-plus-12pk",
      title: "Biotrue ONEday (90 pack)",
      image: "Biotrue-ONEDay-90pk",
      price: "69.99",
      sale_price: "41.99",
      cc_product_id: "1765111332964",
      days: "84",
      quantity: 90
    },
    {
      value: "bausch-lomb-ultra",
      percent_off: "40",
      link: "https://www.contactscart.com/products/bausch-lomb-ultra",
      title: "Bausch + Lomb ULTRA (6 pack)",
      image: "B+L-Ultra-6pk",
      price: "59.99",
      sale_price: "35.99",
      cc_product_id: "1765135908964",
      days: "168",
      quantity: 6
    },
  ]
})
